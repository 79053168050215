import React, { Component } from "react";
import {
	View,
	Image,
	TouchableOpacity,
	Linking,
	Text,
	ScrollView,
	Dimensions,
} from "react-native";
import { crudGetBrandTrackers } from "../../CRUDHELPER";
import { APP_COLOURS } from "../../APP_VARS";

export default class TrackerOverview extends Component {
	constructor(props) {
		super(props);
		this.state = {
			screenHeight: Dimensions.get("window").height,
			screenWidth: Dimensions.get("window").width,
			trackers: [],

			selectdMonth: "",
			brands: [],

			viewTechSupport: false,
		};
	}

	handlePress = async (url, obj) => {
		try {
			const value = await localStorage.setItem(
				"brand_data",
				JSON.stringify(obj),
			);
			// Linking.openURL(url);
			window.location = url;
		} catch (error) {
			return;
		}
	};

	componentDidMount() {
		this.gettrackers();

		window.addEventListener("resize", this.resize.bind(this));
	}

	gettrackers = () => {
		// crudGettrackers((s, d) => {
		// 	if (s === 200) {
		// 		console.log();
		// 		this.setState({
		// 			trackers: d.data,
		// 		});
		// 	}
		// });

		crudGetBrandTrackers((s, d) => {
			if (s === 200) {
				console.log(d.data);
				this.setState({
					brands: d.data,
				});

				// localStorage.setItem("brandData", d.data);
			}
		});
	};

	componentDidUpdate() {}

	resize() {
		this.setState({
			screenHeight: Dimensions.get("window").height,
			screenWidth: Dimensions.get("window").width,
		});
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.resize.bind(this));
	}

	render() {
		let items = this.state.trackers;
		let brands = this.state.brands;
		return (
			<View
				style={{
					flex: 1,
					height: window.innerHeight,
					// backgroundColor: "#1C2B46",

					backgroundColor: "#121419",
					// width: "100%",
					// alignItems: "center",
					overflow: "hidden",
				}}>
				<View
					style={{
						height: window.innerHeight,

						padding: "5%",
						width: "100%",
						// alignItems: "center",
						maxWidth: 1600,
						marginLeft: "auto",
						marginRight: "auto",
					}}>
					<View
						style={{
							width: "100%",
							flexDirection: "row",
							justifyContent: "space-between",
							flexWrap: "wrap-reverse",
						}}>
						<View
							style={{
								// flex: 1,
								flexDirection: "row",
								// minWidth: 320,
								width: "100%",
								paddingBottom: 20,
							}}>
							<TouchableOpacity
								style={{
									// backgroundColor: "#FFF",
									// backgroundColor: "#1F2229",
									// padding: 20,
									borderRadius: 15,
									width: "100%",
									// alignItems: "center",
									// justifyContent: "center",
								}}
								onPress={() =>
									this.setState({
										showSidebar: !this.state.showSidebar,
									})
								}>
								<View
									style={{
										width: "80%",
										maxWidth: 400,
									}}>
									<img
										src={require("../assets/tetrice-revlon-main.png")}
										alt='Description'
										className='responsive'
									/>
								</View>
							</TouchableOpacity>
						</View>
						<View
							style={{
								width: "100%",
								height: 0.5,
								backgroundColor: "#1F2229",
							}}
						/>
					</View>
					<View style={{ marginTop: 50 }}>
						<Text
							ellipsizeMode='tail'
							style={{
								color: APP_COLOURS.DESELECTEDCOLOR,
								fontSize: 30,
								fontWeight: "900",
								// opacity: 0.5,
							}}>
							Digital Tracker
						</Text>
					</View>

					<View
						style={{
							flex: 1,
							flexDirection: "row",
						}}>
						<ScrollView showVerticalIndicator={false}>
							<View
								style={
									{
										// flex: 1,
										// width: "100%",
									}
								}>
								<View
									style={{
										flex: 1,
										flexDirection: "row",
										justifyContent: "space-between",
										width: "100%",
									}}>
									<View
										style={{
											flex: 1,
											paddingTop: 30,
											justifyContent: "flex-start",
											alignItems: "flex-start",
											flexWrap: "wrap",
											// maxWidth: 1200,
										}}>
										{brands
											.filter((d) =>
												this.state.selectdMonth !== ""
													? d.period ===
													  this.state.selectdMonth
													: d,
											)
											.map((item, i) => (
												<TouchableOpacity
													key={i}
													style={{
														padding: 10,
														backgroundColor:
															APP_COLOURS.BG2,
														marginBottom: 20,
														borderRadius: 10,
														width: "100%",
														flexDirection: "row",
														alignItems: "center",
													}}
													onPress={() =>
														this.handlePress(
															`/tracker/${item.url}?token=1234`,
															item,
														)
													}>
													<View
														style={{
															// width: 65,
															// height: 40,
															borderRadius: 10,
															backgroundColor:
																APP_COLOURS.BG5,
															marginRight: 15,
															padding: 10,
															overflow: "hidden",
														}}>
														<Image
															style={{
																width: 89,
																height: 55,
																resizeMode:
																	"cover",
																opacity: 0.7,
															}}
															source={{
																uri: item.logo_icon_url,
															}}
														/>
													</View>
													<Text
														style={{
															fontSize: 16,
															fontWeight: "bold",
															color: "#FFF",
															// opacity: 0.8,
														}}>
														{item.date}
													</Text>
													<View>
														{/* <Text
															style={{
																fontSize: 9,
																color: "#FFF",
																opacity: 0.7,
															}}>
															{item.brand}
														</Text> */}
														<Text
															style={{
																fontSize: 16,
																// fontWeight: "bold",
																color: "#FFF",
																// opacity: 0.8,
															}}>
															{item.title}
														</Text>

														<Text
															style={{
																fontSize: 9,
																color: "#FFF",
																opacity: 0.5,
															}}>
															{item.id}
														</Text>
													</View>
												</TouchableOpacity>
											))}
									</View>
								</View>
							</View>
						</ScrollView>
						<ScrollView showVerticalIndicator={false}>
							<View
								style={
									{
										// flex: 1,
										// width: "100%",
									}
								}>
								<View
									style={{
										flex: 1,
										flexDirection: "row",
										justifyContent: "space-between",
										width: "100%",
									}}>
									<View
										style={{
											flex: 1,
											paddingTop: 30,
											justifyContent: "flex-start",
											alignItems: "flex-start",
											flexWrap: "wrap",
											// maxWidth: 1200,
										}}>
										{items.map((item, i) => (
											<TouchableOpacity
												key={i}
												style={{
													padding: 10,
													// backgroundColor: "#3A475E",
													backgroundColor: "#1F2229",
													marginBottom: 20,
													borderRadius: 10,
													width: "100%",
													minWidth: 300,
													maxWidth: 340,
												}}
												onPress={() =>
													this.handlePress(
														item.url,
														item,
													)
												}>
												<Text
													style={{
														fontSize: 16,
														fontWeight: "bold",
														color: "#FFF",
														opacity: 0.8,
													}}>
													{item.period}
												</Text>
												<Text
													style={{
														fontSize: 16,
														fontWeight: "bold",
														color: "#FFF",
														opacity: 0.8,
													}}>
													{item.title}
												</Text>
												<Text
													style={{
														fontSize: 14,
														color: "#FFF",
														opacity: 0.5,
													}}>
													{item.description}
												</Text>
											</TouchableOpacity>
										))}
									</View>
								</View>
							</View>
						</ScrollView>
					</View>
				</View>
				<View
					style={{
						// flexDirection: "row",
						// justifyContent: "flex-end",
						// maxWidth: 1200,
						// flex: 1,
						// alignItems: "flex-end",
						position: "absolute",
						bottom: 0,
						right: 0,
						zIndex: 99,
						width: 200,
						height: "auto",
						// flexDirection: "row",
						alignItems: "center",
					}}>
					{/* <Image
						source={require("../assets/infod.png")}
						style={{
							width: 200,
							maxWidth: 300,
							height: 30,
							resizeMode: "contain",
						}}
					/> */}
					<Image
						source={require("../assets/services.png")}
						style={{
							width: 200,
							height: 50,
							maxWidth: 300,
							resizeMode: "contain",
						}}
					/>
				</View>
			</View>
		);
	}
}
