import React, { Component } from "react";
import {
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  TextInput,
  Modal,
} from "react-native";
import { APP_COLOURS } from "../../../../APP_VARS";
import { crudCreate, crudUpdate, crudDelete, crudRead } from "../../../../CRUDHELPER";

class SubCategoryManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subcategories: [],
      showAddModal: false,
      showEditModal: false,
      selectedSubcategory: null,
      newSubcategory: {
        id: "",
        title: "",
        description: "",
      },
    };
  }

  componentDidMount() {
    this.loadSubcategories();
  }

  loadSubcategories = async () => {
    try {
      await crudRead("subcategories", {}, (status, result) => {
        if (status === 200) {
          this.setState({ subcategories: result });
          this.props.onSubcategoryChange(result);
        }
      });
    } catch (error) {
      console.error("Error loading subcategories:", error);
    }
  };

  handleAddSubcategory = async () => {
    const { newSubcategory } = this.state;
    if (!newSubcategory.title) return;

    const subcategoryData = {
      ...newSubcategory,
      id: Date.now().toString(),
      created_at: new Date().toISOString(),
    };

    try {
      await crudCreate("subcategories", subcategoryData, (status, result) => {
        if (status === 200) {
          this.setState(prevState => ({
            subcategories: [...prevState.subcategories, result],
            showAddModal: false,
            newSubcategory: { id: "", title: "", description: "" }
          }));
          this.props.onSubcategoryChange([...this.state.subcategories, result]);
        }
      });
    } catch (error) {
      console.error("Error adding subcategory:", error);
    }
  };

  handleUpdateSubcategory = async () => {
    const { selectedSubcategory } = this.state;
    if (!selectedSubcategory || !selectedSubcategory.title) return;

    try {
      await crudUpdate("subcategories", selectedSubcategory, (status) => {
        if (status === 200) {
          this.setState(prevState => ({
            subcategories: prevState.subcategories.map(sub =>
              sub.id === selectedSubcategory.id ? selectedSubcategory : sub
            ),
            showEditModal: false,
            selectedSubcategory: null
          }));
          this.props.onSubcategoryChange(this.state.subcategories);
        }
      });
    } catch (error) {
      console.error("Error updating subcategory:", error);
    }
  };

  handleDeleteSubcategory = async (id) => {
    try {
      await crudDelete("subcategories", { id }, (status) => {
        if (status === 200) {
          this.setState(prevState => ({
            subcategories: prevState.subcategories.filter(sub => sub.id !== id)
          }));
          this.props.onSubcategoryChange(this.state.subcategories);
        }
      });
    } catch (error) {
      console.error("Error deleting subcategory:", error);
    }
  };

  render() {
    const {
      subcategories,
      showAddModal,
      showEditModal,
      selectedSubcategory,
      newSubcategory,
    } = this.state;

    return (
      <View style={{ flex: 1 }}>
        <View style={{ flexDirection: "row", justifyContent: "space-between", padding: 10 }}>
          <Text style={{ fontSize: 20, color: APP_COLOURS.TEXTCOLOR }}>Subcategories</Text>
          <TouchableOpacity
            style={{
              backgroundColor: APP_COLOURS.BLUE,
              padding: 8,
              borderRadius: 5,
            }}
            onPress={() => this.setState({ showAddModal: true })}
          >
            <Text style={{ color: "#FFF" }}>Add Subcategory</Text>
          </TouchableOpacity>
        </View>

        <ScrollView style={{ flex: 1 }}>
          {subcategories.map(subcategory => (
            <View
              key={subcategory.id}
              style={{
                padding: 10,
                borderBottomWidth: 1,
                borderBottomColor: APP_COLOURS.BG3,
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <View>
                <Text style={{ fontSize: 16, color: APP_COLOURS.TEXTCOLOR }}>
                  {subcategory.title}
                </Text>
                <Text style={{ fontSize: 14, color: APP_COLOURS.BG5 }}>
                  {subcategory.description}
                </Text>
              </View>
              <View style={{ flexDirection: "row" }}>
                <TouchableOpacity
                  style={{ marginRight: 10 }}
                  onPress={() =>
                    this.setState({
                      selectedSubcategory: subcategory,
                      showEditModal: true,
                    })
                  }
                >
                  <Text style={{ color: APP_COLOURS.BLUE }}>Edit</Text>
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() => this.handleDeleteSubcategory(subcategory.id)}
                >
                  <Text style={{ color: APP_COLOURS.RED }}>Delete</Text>
                </TouchableOpacity>
              </View>
            </View>
          ))}
        </ScrollView>

        {/* Add Modal */}
        <Modal
          visible={showAddModal}
          transparent
          animationType="fade"
        >
          <View style={{
            flex: 1,
            backgroundColor: "rgba(0,0,0,0.5)",
            justifyContent: "center",
            alignItems: "center",
          }}>
            <View style={{
              backgroundColor: APP_COLOURS.BG,
              padding: 20,
              borderRadius: 10,
              width: "80%",
              maxWidth: 500,
            }}>
              <Text style={{ fontSize: 18, color: APP_COLOURS.TEXTCOLOR, marginBottom: 15 }}>
                Add New Subcategory
              </Text>
              <TextInput
                style={{
                  backgroundColor: APP_COLOURS.BG3,
                  padding: 10,
                  borderRadius: 5,
                  marginBottom: 10,
                  color: APP_COLOURS.TEXTCOLOR,
                }}
                placeholder="Title"
                placeholderTextColor={APP_COLOURS.BG5}
                value={newSubcategory.title}
                onChangeText={text =>
                  this.setState(prevState => ({
                    newSubcategory: { ...prevState.newSubcategory, title: text },
                  }))
                }
              />
              <TextInput
                style={{
                  backgroundColor: APP_COLOURS.BG3,
                  padding: 10,
                  borderRadius: 5,
                  marginBottom: 15,
                  color: APP_COLOURS.TEXTCOLOR,
                }}
                placeholder="Description"
                placeholderTextColor={APP_COLOURS.BG5}
                value={newSubcategory.description}
                onChangeText={text =>
                  this.setState(prevState => ({
                    newSubcategory: { ...prevState.newSubcategory, description: text },
                  }))
                }
              />
              <View style={{ flexDirection: "row", justifyContent: "flex-end" }}>
                <TouchableOpacity
                  style={{ marginRight: 10 }}
                  onPress={() => this.setState({ showAddModal: false })}
                >
                  <Text style={{ color: APP_COLOURS.BG5 }}>Cancel</Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={{
                    backgroundColor: APP_COLOURS.BLUE,
                    padding: 8,
                    borderRadius: 5,
                  }}
                  onPress={this.handleAddSubcategory}
                >
                  <Text style={{ color: "#FFF" }}>Add</Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </Modal>

        {/* Edit Modal */}
        <Modal
          visible={showEditModal}
          transparent
          animationType="fade"
        >
          <View style={{
            flex: 1,
            backgroundColor: "rgba(0,0,0,0.5)",
            justifyContent: "center",
            alignItems: "center",
          }}>
            <View style={{
              backgroundColor: APP_COLOURS.BG,
              padding: 20,
              borderRadius: 10,
              width: "80%",
              maxWidth: 500,
            }}>
              <Text style={{ fontSize: 18, color: APP_COLOURS.TEXTCOLOR, marginBottom: 15 }}>
                Edit Subcategory
              </Text>
              <TextInput
                style={{
                  backgroundColor: APP_COLOURS.BG3,
                  padding: 10,
                  borderRadius: 5,
                  marginBottom: 10,
                  color: APP_COLOURS.TEXTCOLOR,
                }}
                placeholder="Title"
                placeholderTextColor={APP_COLOURS.BG5}
                value={selectedSubcategory?.title || ""}
                onChangeText={text =>
                  this.setState(prevState => ({
                    selectedSubcategory: {
                      ...prevState.selectedSubcategory,
                      title: text,
                    },
                  }))
                }
              />
              <TextInput
                style={{
                  backgroundColor: APP_COLOURS.BG3,
                  padding: 10,
                  borderRadius: 5,
                  marginBottom: 15,
                  color: APP_COLOURS.TEXTCOLOR,
                }}
                placeholder="Description"
                placeholderTextColor={APP_COLOURS.BG5}
                value={selectedSubcategory?.description || ""}
                onChangeText={text =>
                  this.setState(prevState => ({
                    selectedSubcategory: {
                      ...prevState.selectedSubcategory,
                      description: text,
                    },
                  }))
                }
              />
              <View style={{ flexDirection: "row", justifyContent: "flex-end" }}>
                <TouchableOpacity
                  style={{ marginRight: 10 }}
                  onPress={() =>
                    this.setState({ showEditModal: false, selectedSubcategory: null })
                  }
                >
                  <Text style={{ color: APP_COLOURS.BG5 }}>Cancel</Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={{
                    backgroundColor: APP_COLOURS.BLUE,
                    padding: 8,
                    borderRadius: 5,
                  }}
                  onPress={this.handleUpdateSubcategory}
                >
                  <Text style={{ color: "#FFF" }}>Update</Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </Modal>
      </View>
    );
  }
}

export default SubCategoryManager;
